const valvelet = require('valvelet');
const DBBQ = require('./dbbq');
const {
  TelemetryLogSendError,
} = require('./logger/errors/telemetryLogSendError');
const traceCreators = require('./logger/traceCreators');
const { sendTelemetryLog } = require('./sendTelemetryLog');

const throttledLogSender = ({
  fedopsLogger,
  appLogger,
  requestLimit = 1,
  requestInterval = 1000,
  logsPerBatch = 10,
  batchDrainTimeout = 500,
}) => {
  const sendRequest = (logs) => {
    const traceConfig = traceCreators.sendTelemetryMessages();
    fedopsLogger.interactionStarted(traceConfig.actionName);
    const url = '/wix-code-telemetry-collector/v1/telemetry-messages';
    sendTelemetryLog(url, logs)
      .then(() => {
        fedopsLogger.interactionEnded(traceConfig.actionName);
      })
      // TODO:  break down logs in case of network issue?
      .catch((e) => {
        appLogger.error(new TelemetryLogSendError(e, logs));
      });
  };

  const batchedLogsQueue = DBBQ.create(batchDrainTimeout, logsPerBatch);
  const sendLogThrottled = (log) => batchedLogsQueue.add(log);

  const throttledSendRequest = valvelet(
    sendRequest,
    requestLimit,
    requestInterval,
    10000, // queue size
  );
  batchedLogsQueue.onData((logs) => throttledSendRequest(logs));

  return {
    sendLogThrottled,
  };
};

module.exports.throttledLogSender = throttledLogSender;
