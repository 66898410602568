const { buildNamespacesMap } = require('@wix/wix-code-viewer-utils');
const { createClientSdk } = require('../utils/createClientSdk');

const createGlobals = ({
  active$wBiFactory,
  $w,
  wixSdk,
  userConsole,
  getAppDefIdFromPackageName,
}) => {
  const wrapped$w = active$wBiFactory.wrapFunctionReturnValueWithBi($w);
  wrapped$w.at = active$wBiFactory.wrapFunctionCallWithBi($w.at, $w);

  const ignoredWixSdkApis = [
    // this is an internal namespace
    'events',
    // that's not a namespace, users won't use it so we don't need to expose it
    'dashboardHost',
  ];

  const wrappedWixSdk = buildNamespacesMap(
    wixSdk,
    self.fetch.bind(self),
    active$wBiFactory.wrapObjectPropertiesWithBi,
    ignoredWixSdkApis,
  );

  const { client, hostImplementation } = createClientSdk(wixSdk);

  return {
    $w: wrapped$w,
    $ns: wrappedWixSdk,
    console: userConsole,
    $wixContext: {
      version: 3,
      client,
      clientSdk: hostImplementation,
    },
    elementorySupport: wixSdk.elementorySupport,
    getAppDefIdFromPackageName, // corresponding change in the bundling side can be found here: https://github.com/wix-private/cloud-runtime/pull/4025
  };
};

module.exports = {
  createGlobals,
};
